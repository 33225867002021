import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Lottie from 'lottie-react-web';
import {
  FaMobileAlt,
  FaCode,
  FaSearch,
  FaWordpress,
  FaCloud,
  FaSmile,
  FaEnvelope,
} from 'react-icons/fa';
import { SiAzuredevops } from 'react-icons/si';
import SEO from '../components/seo';
import '../styles/theme.scss';
import avatar from '../images/avatar.json';

const About = () => (
  <>
    <SEO title="About Me" />
    <Container fluid>
      <Row className="pageHeader">
        <Col>
          <h1>ABOUT ME</h1>
        </Col>
        <Col>
          <h5>Let's Get To Know About Me.</h5>
        </Col>
      </Row>

      <Row className="ProjectsContainer">
        <Col lg="4" md="12">
          <blockquote className="blockquote blockquote-custom bg-white p-5 shadow rounded">
            <div className="blockquote-custom-icon bg-info shadow-sm">
              <FaSmile />
            </div>
            <div className="aniBox">
              <Lottie
                options={{
                  animationData: avatar,
                }}
              />
            </div>
          </blockquote>
        </Col>
        <Col lg="8" md="12">
          <div className="about-me">
            <h3>Jimmy Truong</h3>
            <p>
              Experienced Full Stack Developer with a demonstrated history of
              working in the application development industry.
            </p>
            <p>
              Skilled in JavaScript as well as its modern frameworks and
              libraries including React, Vue, Next.js, Gatsby. Also proficient
              in Back-end and DevOps technologies such as Node.js, PHP, MySQL,
              GraphQL, REST API, Jenkins, Cypress, and Docker. Strong aspiring
              developer graduated from British Columbia Institute of Technology.
            </p>
            <div className="sig-wrap">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="signature"
                width="300px"
                length="auto"
                viewBox="0 0 1000 500"
              >
                <path
                  className="j"
                  fill="none"
                  stroke="#4274b5"
                  d="M68.8,159.9c27.4,1.7,54.9,0.7,82.3-1.1c13.4-0.8,26.8-1.9,40.2-2.8c13.8-1,28-2.8,41.8-1c-0.5-0.5-1-1-1.5-1.5
																				c-1.2,11-4.9,21-4.2,32.2c0.7,10.7,3.7,21,7.3,31c7.5,20.9,18.8,41.5,18.8,64.3c0.1,43.3-47.4,68.7-85.3,72.5
																				c-10.8,1.1-21.8,0.8-32.6-0.9c-5.9-0.9-12.2-2-17.6-4.8c-6.2-3.3-11.4-10.3-8-17.5c0.8-1.7-1.8-3.3-2.6-1.5
																				c-3.6,7.7,0.2,15.9,7,20.4c8.5,5.6,20.3,6.7,30.2,7.5c20.6,1.7,41.7-1.4,60.7-9.7c19-8.3,37.5-22.6,46.2-41.9
																				c9.8-21.7,3.1-45.4-5.1-66.5c-5.2-13.3-11.1-26.4-14.2-40.4c-1.7-7.6-2.5-15.5-1.6-23.3c0.8-7.2,3.2-14.1,4-21.3
																				c0.1-0.9-0.8-1.4-1.5-1.5c-13.5-1.7-27.3,0-40.8,1c-13.7,1-27.4,2-41.2,2.9c-27.4,1.7-54.9,2.7-82.3,1.1
																				C66.9,156.8,66.9,159.8,68.8,159.9L68.8,159.9z"
                />
                <path
                  className="i"
                  fill="none"
                  stroke="#4274b5"
                  d="M309.1,260.3c0.1,9.1,0.2,18.2,0.3,27.4c0.1,7.4,0.4,15,2.6,22.2c1.6,5.2,4.4,11.3,9.6,13.8c6,2.8,10.6-2,14.2-6.3
																				c10-12,16.3-26.6,26-38.7c3-3.7,8.1-10.7,13.3-6.3c3.1,2.6,4.3,7.5,5.4,11.2c2.6,8.8,3.5,17.9,3,27c1-0.1,2-0.3,2.9-0.4
																				c-2.3-13.6,3.4-29.5,16-36.3c8-4.3,16.2-1,20.9,6.4c4,6.4,4.4,14.3,4.9,21.7c0.1,2,2.7,1.9,3,0c2.4-18.2,18.1-37.5,37.9-36.8
																				c23.4,0.8,23,30,28.8,46.1c0.6,1.8,3.4,1.1,2.9-0.8c-4-15.2,0.8-34.2,14.5-43.1c9.1-5.9,17.8-1.5,22.9,7.1
																				c4.6,7.9,6.2,17,5.3,26.1c1-0.1,2-0.3,2.9-0.4c-3.9-12.9-2.8-32.1,13.4-35.7c13.7-3.1,20.9,12.2,24.1,22.8
																				c0.5,1.5,3.2,1.3,2.9-0.4c-1-8.3-2.5-18.8,3.8-25.5c2.8-3,7.2-5.2,11.4-4.2c4.3,1.1,6.6,5.3,8.3,9c5.8,13,7.5,28.5,19.6,37.7
																				c11.6,8.8,23.1,3.7,32.8-5.1c13.7-12.4,27.2-25.1,40.9-37.7c-0.7-0.7-1.4-1.4-2.1-2.1c-22.2,23.3-10.9,58-19.9,86
																				c-9.1,28.6-34.9,48.5-62.2,58.5c-31.1,11.3-65.5,13-98.3,13.4c-34.3,0.4-68.5-2.1-102.3-7.7c-37.6-6.2-74.6-16-110.3-29.3
																				c-1.8-0.7-2.6,2.2-0.8,2.9c64.6,24,133.4,36.7,202.3,37.1c57.4,0.4,134.4-2.3,167.4-58.2c8.7-14.8,10.8-30.9,11.5-47.8
																				c0.7-18.4,1.3-38.6,14.7-52.8c1.3-1.4-0.7-3.4-2.1-2.1c-14.4,13.3-28.3,27.3-43.3,39.8c-7.2,6.1-15.6,9.3-24.4,4.1
																				c-8-4.7-12-13.4-15-21.8c-3.4-9.5-6.8-28.8-20.6-27.1c-16,2-16,20.9-14.5,32.8c1-0.1,2-0.3,2.9-0.4c-3.7-12.3-12.3-28.5-27.8-24.9
																				c-17.8,4.1-19.9,24.8-15.5,39.4c0.5,1.7,2.8,1.2,2.9-0.4c1.5-15.1-7.4-46-28.5-37.9c-18.2,7-24.7,31.5-20.1,48.7
																				c1-0.3,1.9-0.5,2.9-0.8c-6.5-18-6.7-49-33.2-48.3c-21,0.5-36.7,20.3-39.4,39.8c1,0,2,0,3,0c-0.6-8.9-1.3-18.5-7.2-25.7
																				c-4.9-6-12.4-9-19.9-6.4c-16.1,5.6-23.3,25.6-20.6,41.1c0.3,1.7,2.9,1.2,2.9-0.4c0.4-8.2-0.2-16.3-2.2-24.2
																				c-1.3-5.5-3.1-13.3-8.1-16.9c-10.4-7.4-21.1,13-25.3,19.7c-4.1,6.6-8,13.4-12.4,19.8c-2.4,3.5-5,7.1-8.1,10
																				c-5.8,5.4-11.1,0.1-13.8-5.5c-3.8-7.9-4.1-17-4.2-25.6c-0.2-9.1-0.2-18.2-0.3-27.4C312.1,258.4,309.1,258.4,309.1,260.3
																				L309.1,260.3z"
                />
                <path
                  className="m1"
                  fill="none"
                  stroke="#4274b5"
                  d="M310.6,230.4c0.2,0,0.3,0,0.5,0c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.2,0-0.3,0-0.5,0c-0.8,0-1.5,0.7-1.5,1.5
																				C309.1,229.7,309.7,230.4,310.6,230.4L310.6,230.4z"
                />
              </svg>
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="pageHeader">
        <Col>
          <h1>WHAT I USE</h1>
        </Col>
        <Col>
          <h5>Here are some of my expertise:</h5>
        </Col>
      </Row>

      <Row className="ProjectsContainer">
        <Col xl="6" lg="12">
          <div className="jimmy-feature">
            <div className="jimmy-icon">
              <FaMobileAlt />
            </div>
            <div className="jimmy-text">
              <h3>MOBILE APPLICATION</h3>
              <p>Swift, React Native</p>
            </div>
          </div>
          <div className="jimmy-feature">
            <div className="jimmy-icon">
              <FaCode />
            </div>
            <div className="jimmy-text">
              <h3>Progressive Web Application</h3>
              <p>Next.js, Gatsby, Nuxt.js</p>
            </div>
          </div>
          <div className="jimmy-feature">
            <div className="jimmy-icon">
              <FaSearch />
            </div>
            <div className="jimmy-text">
              <h3>Search engine optimization (SEO)</h3>
              <p />
            </div>
          </div>
        </Col>
        <Col xl="6" lg="12">
          <div className="jimmy-feature">
            <div className="jimmy-icon">
              <FaCloud />
            </div>
            <div className="jimmy-text">
              <h3>CLOUD COMPUTING SERVICES</h3>
              <p>AWS, GCP, Microsoft Azure, Heroku.</p>
            </div>
          </div>
          <div className="jimmy-feature">
            <div className="jimmy-icon">
              <SiAzuredevops />
            </div>
            <div className="jimmy-text">
              <h3>DEVOPS</h3>
              <p>Cypress, Puppeteer, Jenkins, Travis CI, Docker, Kubernetes</p>
            </div>
          </div>
          <div className="jimmy-feature">
            <div className="jimmy-icon">
              <FaWordpress />
            </div>
            <div className="jimmy-text">
              <h3>CONTENT MANAGEMENT SYSTEM</h3>
              <p>WordPress, Shopify, Squarespace.</p>
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="pageHeader mb-4">
        <Col>
          <h1>GET IN TOUCH</h1>
          <h5>
            I'm always open for freelance projects. You can get in touch anytime
            through:
          </h5>
        </Col>
        <Col>
          <p className="contact-info">
            <span>
              <FaEnvelope />
            </span>{' '}
            <a href="mailto:jimmy121192@gmail.com">jimmy121192@gmail.com</a>
          </p>
        </Col>
      </Row>
    </Container>
  </>
);
export default About;
